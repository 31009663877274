<template>
  <div class="d-flex search-container align-items-center" :class="className">
    <SearchOutlined class="search-icon" />
    <input
      v-model="searchString"
      :class="[
      'form-control',
      `form-control-${inputSize}`,
      'holder-searchbar',
      ]"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
    />
    <SwapOutlined v-if="allowIdSearch"
      type="button"
      class="ms-2 pe-1"
      @click="swapToID"
      v-tooltip="swapToolTip"
    />
    <CloseOutlined
      type="button"
      v-if="searchString"
      class="ms-2 pe-1"
      @click="clearSearch"
    />

  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from "vue";
import { SearchOutlined, CloseOutlined, SwapOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "base-search-input",
  components: {
    SearchOutlined,
    CloseOutlined,
    SwapOutlined
  },
  props: {
    id: {
      type: String,
      default: "custom-search-input",
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    inputSize: {
      type: String,
      default: "sm",
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    className: {
      type: String
    },
    swapToolTip: {
      type: String,
      default: "Swap to search by Product ID"
    },
    allowIdSearch: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props, { emit }) {

    const MAX_INPUT_LENGTH = 100;

    const searchString = computed({
      get() {
        return props.modelValue;
      },
      set(value: any) {
        if (typeof value === "string" && value.length > MAX_INPUT_LENGTH) {
          value = value.substring(0, MAX_INPUT_LENGTH);
        }
        emit("update:modelValue", value);
      },
    });


    const clearSearch = () => {
      searchString.value = "";
    };

    const swapToID = () => {
      emit('switchSearchField')
    }

    return {
      searchString,
      clearSearch,
      swapToID
    };
  },
});
</script>
<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/variables";

.search-container {
  border: 1px solid $gray-400;
  border-radius: 3px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  .search-icon {
    color: $gray-700;
  }

  .holder-searchbar {
    color: $gray-700;
    border: 0;
    margin: 0;
    &::placeholder {
      color: $gray-700;
      opacity: 1; /* Firefox */
    }
  }
}
</style>
