<template>
    <transition name="fade">
        <button v-if="isVisible" class="icon-button"  @click="scrollToTop" aria-label="Scroll to top">
            <i class="fa  fa-arrow-up back-button bottom-4 right-4" aria-hidden="true"></i>
        </button>
    </transition>
    
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import store from "@/store";

export default defineComponent({
    name: 'ScrollToTop',
    props: {
        darkBackground: {
            type: Boolean,
            default: false
        }
    },

    setup() {
        const isVisible = ref(false);

        onMounted(() => {
            window.addEventListener('scroll', handleScroll)
        })

        onUnmounted(()=> {
            window.removeEventListener('scroll', handleScroll)
        })

        const handleScroll = () => {
            isVisible.value = window.scrollY > 300;
        };

        const scrollToTop = () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        };

        return {
            isVisible,
            scrollToTop
        }
    }
});
</script>

<style scoped lang="scss">
@import "~bootstrap/scss/_functions";
@import "@/assets/scss/custom/_functions";
@import "@/assets/scss/custom/_variables";
@import "~bootstrap/scss/_variables";

.icon-button {
    position: fixed;
    bottom: 30px; /* Adjust to position near the bottom */
    right: 20px; /* Adjust to position near the right */
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary; /* Replace with your desired color */
    color: white;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    z-index: 999009; /* Ensure it's above other elements */
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.icon-button:hover {
    background-color: white;
    color: $primary;
}

.back-button {
    font-size: 16px;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-in-out; /* Adjust duration as needed */
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
