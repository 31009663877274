import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d701089a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pc-tabs" }
const _hoisted_2 = { class: "nav nav-tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "d-flex align-items-center justify-content-center" }
const _hoisted_5 = { class: "tab-content" }
const _hoisted_6 = { ref: "fieldTabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WarningOutlined = _resolveComponent("WarningOutlined")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "nav-item",
            key: index
          }, [
            _createElementVNode("a", {
              href: "#",
              class: _normalizeClass(["nav-link", { 'active': tab.isActive }]),
              onClick: ($event: any) => (_ctx.activateTab(index, _ctx.tabIndex))
            }, [
              _createElementVNode("span", _hoisted_4, [
                _createTextVNode(_toDisplayString(tab.title) + " ", 1),
                (tab.hasWarning)
                  ? (_openBlock(), _createBlock(_component_WarningOutlined, {
                      key: 0,
                      class: "tab-icon-close"
                    }))
                  : _createCommentVNode("", true)
              ])
            ], 10, _hoisted_3)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "default", { tabIndex: _ctx.tabIndex }, undefined, true)
      ], 512)
    ])
  ]))
}