export const productCategoryDict: any = {
    RESIDENTIAL_MORTGAGES: "Residential Mortgages",
    CRED_AND_CHRG_CARDS: "Credit & Charge Cards",
    PERS_LOANS: "Personal Loans",
    MARGIN_LOANS: "Margin Loans",
    LEASES: "Leases",
    TRADE_FINANCE: "Trade Finance",
    OVERDRAFTS: "Overdrafts",
    BUSINESS_LOANS: "Business Loans",
    TRANS_AND_SAVINGS_ACCOUNTS: "Transaction & Savings Accounts",
    TERM_DEPOSITS: "Term Deposits",
    TRAVEL_CARDS: "Travel Cards",
    REGULATED_TRUST_ACCOUNTS: "Regulated Trust Accounts"
};

export const constraintTypeDict: any = {
    MIN_BALANCE: "Minimum Balance",
    MAX_BALANCE: "Maximum Balance",
    OPENING_BALANCE: "Opening Balance",
    MAX_LIMIT: "Maximum Credit Limit",
    MIN_LIMIT: "Minimum Credit Limit"
};

export const constraintAdditionalValueLabelDict: any = {
    MIN_BALANCE: "Minimum Balance Amount",
    MAX_BALANCE: "Maximum Balance Amount",
    OPENING_BALANCE: "Opening Balance Amount",
    MAX_LIMIT: "Maximum Credit Limit Amount",
    MIN_LIMIT: "Minimum Credit Limit Amount"
};

export const changeTypeDict: any = {
    UPDATED: "Updated",
    ADDED: "Added",
    REMOVED: "Removed"
}

export const labelTypeDict: any = {
    fees: "Fees",
    features: "Features",
    other: "Other Fields", // This is valid
    constraints: "Constraints",
    rates: "Rates",
};


export const depositRateTypeDict: any = {
    FIXED: "Fixed",
    BONUS: "Bonus",
    BUNDLE_BONUS: "Bundle Bonus",
    VARIABLE: "Variable",
    INTRODUCTORY: "Introductory",
    FLOATING: "Floating",
    MARKET_LINKED: "Market Linked"
};

export const depositRateAdditionalValueLabelDict: any = {
    FIXED: "Fixed Period",
    BONUS: "Description Of Bonus Rate",
    BUNDLE_BONUS: "Bundle Name",
    VARIABLE: "Additional Value",
    INTRODUCTORY: "Introductory Period",
    FLOATING: "Details Of Float Parameters",
    MARKET_LINKED: "Details Of Market Linkage"
};

export const lendingRateTypeDict: any = {
    FIXED: "Fixed",
    VARIABLE: "Variable",
    INTRODUCTORY: "Introductory",
    FLOATING: "Floating",
    MARKET_LINKED: "Market Linked",
    DISCOUNT: "Discount",
    PENALTY: "Penalty",
    CASH_ADVANCE: "Cash Advance",
    PURCHASE: "Purchase",
    BUNDLE_DISCOUNT_FIXED: "Bundle Discount Fixed",
    BUNDLE_DISCOUNT_VARIABLE: "Bundle Discount Variable"
};

export const lendingRateAdditionalValueLabelDict: any = {
    FIXED: "Fixed Period",
    VARIABLE: "Additional Value",
    INTRODUCTORY: "Introductory Period",
    FLOATING: "Details Of Float Parameters",
    MARKET_LINKED: "Details Of Market Linkage",
    DISCOUNT: "Description Of Discount Rate",
    PENALTY: "Description Of Penalty Rate",
    CASH_ADVANCE: "Additional Value",
    PURCHASE: "Additional Value",
    BUNDLE_DISCOUNT_FIXED: "Bundle Name",
    BUNDLE_DISCOUNT_VARIABLE: "Bundle Name"
};

export const interestPaymentDueDict: any = {
    IN_ARREARS: "In Arrears",
    IN_ADVANCE: "In Advance"
};

export const repaymentTypeDict: any = {
    INTEREST_ONLY: "Interest only",
    PRINCIPAL_AND_INTEREST: "Principal and Interest"
};

export const loanPurposeDict: any = {
    OWNER_OCCUPIED: "Owner occupied",
    INVESTMENT: "Investment"
};

export const eligibilityTypeDict: any = {
    BUSINESS: "Business",
    PENSION_RECIPIENT: "Pension Recipient",
    MIN_AGE: "Minimum Age",
    MAX_AGE: "Maximum Age",
    MIN_INCOME: "Minimum Income",
    MIN_TURNOVER: "Minimum Turnover",
    STAFF: "Staff",
    STUDENT: "Student",
    EMPLOYMENT_STATUS: "Employment Status",
    RESIDENCY_STATUS: "Residency Status",
    NATURAL_PERSON: "Natural Person",
    OTHER: "Other"
};

export const lvrDict: any = {
    "<=60": "<=60",
    "60-80": "60-80",
    "80-90": "80-90",
    "90-95": "90-95"
}

export const rateTypeDict: any = {
    BASE: "Compare base rate",
    COMPARISON: "Compare comparison rate",
    BASE_COMPARISON: "Compare both"
}

export const splunkFrequencyDict: any = {
    5: "5 min",
    15: "15 min",
    30: "30 min",
    60: "1 Hr"
}

export const yearFixedDict: any = {
    variableBaseRate: "Variable base rate",
    oneYearFixedBaseRate: "1 year fixed base rate",
    twoYearFixedBaseRate: "2 year fixed base rate",
    threeYearFixedBaseRate: "3 year fixed base rate",
    fourYearFixedBaseRate: "4 year fixed base rate",
    fiveYearFixedBaseRate: "5 year fixed base rate",
    variableComparisonRate: "Variable comparison rate",
    oneYearFixedComparisonRate: "1 year fixed comparison rate",
    twoYearFixedComparisonRate: "2 year fixed comparison rate",
    threeYearFixedComparisonRate: "3 year fixed comparison rate",
    fourYearFixedComparisonRate: "4 year fixed comparison rate",
    fiveYearFixedComparisonRate: "5 year fixed comparison rate",
}

export const eligibilityAdditionalValueLabelDict: any = {
    BUSINESS: "Additional Value",
    PENSION_RECIPIENT: "Description Of Pension",
    MIN_AGE: "Minimum Age In Years",
    MAX_AGE: "Maximum Age In Years",
    MIN_INCOME: "Minimum Income Amount",
    MIN_TURNOVER: "Minimum Turnover Amount",
    STAFF: "Additional Value",
    STUDENT: "Description Of Student Eligibility",
    EMPLOYMENT_STATUS: "Description Of Required Status",
    RESIDENCY_STATUS: "Description Of Required Status",
    NATURAL_PERSON: "Additional Value",
    OTHER: "Additional Value"
};

export const featureTypeDict: any = {
    CARD_ACCESS: "Card Access",
    ADDITIONAL_CARDS: "Additional Cards",
    UNLIMITED_TXNS: "Unlimited Transactions",
    FREE_TXNS: "Free Transactions",
    FREE_TXNS_ALLOWANCE: "Free Transaction Allowance",
    LOYALTY_PROGRAM: "Loyalty Program",
    OFFSET: "Offset",
    OVERDRAFT: "Overdraft",
    REDRAW: "Redraw",
    INSURANCE: "Insurance",
    BALANCE_TRANSFERS: "Balance Transfer",
    INTEREST_FREE: "Interest Free",
    INTEREST_FREE_TRANSFERS: "Interest Free Transfers",
    DIGITAL_WALLET: "Digital Wallet",
    DIGITAL_BANKING: "Digital Banking",
    NPP_PAYID: "NPP PayID",
    NPP_ENABLED: "NPP Enabled",
    DONATE_INTEREST: "Donate Interest",
    BILL_PAYMENT: "Bill Payment",
    COMPLEMENTARY_PRODUCT_DISCOUNTS: "Complementary Product Discounts",
    BONUS_REWARDS: "Bonus Rewards",
    NOTIFICATIONS: "Notifications",
    FRAUD_PROTECTION: "Fraud Protection",
    CASHBACK_OFFER: "Cashback Offer",
    EXTRA_REPAYMENTS: "Extra Repayments",
    GUARANTOR: "Guarantor",
    INSTALMENT_PLAN: "Instalment Plan",
    RELATIONSHIP_MANAGEMENT: "Relationship Management",
    OTHER: "Other"
};

export const featureAdditionalValueLabelDict: any = {
    CARD_ACCESS: "Card Types",
    ADDITIONAL_CARDS: "No. Additional Cards",
    UNLIMITED_TXNS: "Additional Value",
    FREE_TXNS: "No. Free Transactions",
    FREE_TXNS_ALLOWANCE: "Amount Discounted",
    LOYALTY_PROGRAM: "Program Name",
    OFFSET: "Additional Value",
    OVERDRAFT: "Additional Value",
    REDRAW: "Additional Value",
    INSURANCE: "Insurance Product Description",
    BALANCE_TRANSFERS: "Additional Value",
    INTEREST_FREE: "Interest Free Period",
    INTEREST_FREE_TRANSFERS: "Interest Free Period",
    DIGITAL_WALLET: "Wallet Type",
    DIGITAL_BANKING: "Additional Value",
    NPP_PAYID: "Additional Value",
    NPP_ENABLED: "Additional Value",
    DONATE_INTEREST: "Additional Value",
    BILL_PAYMENT: "Payment Service Name",
    COMPLEMENTARY_PRODUCT_DISCOUNTS: "Discount Description",
    BONUS_REWARDS: "Points Available",
    NOTIFICATIONS: "Notification Description",
    FRAUD_PROTECTION: "Additional Value",
    CASHBACK_OFFER: "Cashback Amount",
    EXTRA_REPAYMENTS: "Additional Value",
    GUARANTOR: "Additional Value",
    INSTALMENT_PLAN: "Additional Value",
    RELATIONSHIP_MANAGEMENT: "Additional Value",
    OTHER: "Additional Value"
};

export const feeTypeDict: any = {
    PERIODIC: "Periodic",
    TRANSACTION: "Transaction",
    WITHDRAWAL: "Withdrawal",
    DEPOSIT: "Deposit",
    PAYMENT: "Payment",
    PURCHASE: "Purchase",
    EVENT: "Event",
    UPFRONT: "Upfront",
    VARIABLE: "Variable",
    EXIT: "Exit"
};

export const feeAdditionalValueLabelDict: any = {
    PERIODIC: "Period For Charge",
    TRANSACTION: "Additional Value",
    WITHDRAWAL: "Additional Value",
    DEPOSIT: "Additional Value",
    PAYMENT: "Additional Value",
    PURCHASE: "Additional Value",
    EVENT: "Additional Value",
    UPFRONT: "Additional Value",
    VARIABLE: "Additional Value",
    EXIT: "Additional Value"
};

export const discountTypeDict: any = {
    BALANCE: "Balance",
    DEPOSITS: "Deposits",
    PAYMENTS: "Payments",
    FEE_CAP: "Fee Cap",
    ELIGIBILITY_ONLY: "Eligibility Only"
};

export const discountAdditionalValueLabelDict: any = {
    BALANCE: "Minimum Balance Amount",
    DEPOSITS: "Minimum Deposits Amount",
    PAYMENTS: "Payment Threshold",
    FEE_CAP: "Duration Of Fee Cap",
    ELIGIBILITY_ONLY: "Additional Value"
};

export const discountEligibilityTypeDict: any = {
    BUSINESS: "Business",
    PENSION_RECIPIENT: "Pension Recipient",
    MIN_AGE: "Minimum Age",
    MAX_AGE: "Maximum Age",
    MIN_INCOME: "Minimum Income",
    MIN_TURNOVER: "Minimum Turnover",
    STAFF: "Staff",
    STUDENT: "Student",
    EMPLOYMENT_STATUS: "Employment Status",
    RESIDENCY_STATUS: "Residency Status",
    NATURAL_PERSON: "Natural Person",
    INTRODUCTORY: "Introductory",
    OTHER: "Other"
}

export const discountEligibilityAdditionalValueLabelDict: any = {
    BUSINESS: "Additional Value",
    PENSION_RECIPIENT: "Description Of Pension",
    MIN_AGE: "Minimum Age In Years",
    MAX_AGE: "Maximum Age In Years",
    MIN_INCOME: "Minimum Income Amount",
    MIN_TURNOVER: "Minimum Turnover Amount",
    STAFF: "Additional Value",
    STUDENT: "Description Of Student Eligibility",
    EMPLOYMENT_STATUS: "Description Of Required Status",
    RESIDENCY_STATUS: "Description Of Required Status",
    NATURAL_PERSON: "Additional Value",
    INTRODUCTORY: "Introductory Period",
    OTHER: "Additional Value"
}

export const unitOfMeasureDict: any = {
    DOLLAR: "Dollars",
    PERCENT: "Percent",
    MONTH: "Month(s)",
    DAY: "Day(s)"
};

export const rateApplicationMethodDict: any = {
    WHOLE_BALANCE: "Whole Balance",
    PER_TIER: "Per Tier"
};

export const productDataKeys: any = [
    { key: "description", label: "Description" },
    { key: "brand", label: "Brand" },
    { key: "brandName", label: "Brand Name" },
    { key: "lastUpdated", label: "Last Updated" },
    { key: "isTailored", label: "Tailored?" },
    { key: "effectiveFrom", label: "Effective From" },
    { key: "effectiveTo", label: "Effective To" },
    { key: "applicationUri", label: "Application Link" },
    { key: "additionalInformation", label: "Additional Information" },
    { key: "bundles", label: "Bundles" },
    { key: "constraints", label: "Constraints" },
    { key: "depositRates", label: "Deposit Rates" },
    { key: "lendingRates", label: "Lending Rates" },
    { key: "eligibility", label: "Eligibility" },
    { key: "eligibilities", label: "Eligibility" },
    { key: "features", label: "Features" },
    { key: "fees", label: "Fees" }
];

export const userActivityLogDict: any = {
    OUTAGE_CREATE: "Outage Create",
    OUTAGE_DELETE: "Outage Delete",
    OUTAGE_UPDATE: "Outage Update",
    STATUS_UPDATE: "Status Update",
    PRODUCT_CREATE: "Product Create",
    PRODUCT_READ: "Product Read",
    PRODUCT_UPDATE: "Product Update",
    MESSAGE: "Message",
    WORKFLOW_CREATE: "Workflow Create",
    WORKFLOW_READ: "Workflow Read",
    WORKFLOW_UPDATE: "Workflow Update",
    TMD_CREATE: "Target Market Create",
    TMD_READ: "Target Market Read",
    TMD_UPDATE: "Target Market Update",
    TMD_WORKFLOW_CREATE: "Target Market Workflow Create",
    TMD_WORKFLOW_READ: "Target Market Workflow Read",
    TMD_WORKFLOW_UPDATE: "Target Market Workflow Update",
    GOVERNANCE_WORKFLOW_CREATE: "Governance Workflow Create",
    GOVERNANCE_WORKFLOW_READ: "Governance Workflow Read",
    GOVERNANCE_WORKFLOW_UPDATE: "Governance Workflow Update",
    SETTINGS_UPDATE: "Settings Update",
    SECURITY_FORGOT_PASSWORD_RESET: "Reset Forgot Password",
    SECURITY_FORGOT_PASSWORD_EMAIL: "Forgot Password Email Sent",
    SECURITY_CHANGE_PASSWORD_SUCCESS: "Change Password Success",
    SECURITY_CHANGE_PASSWORD_FAIL: "Change Password Fail",
    SECURITY_LOGIN_FAILED: "Login Failed",
    SECURITY_LOGIN_SUCCESSFULLY: "Login Success"
}

export const tagTypeDict: any = {
    BOOLEAN: 'True/False Flag',
    STRING: 'String',
    IDENTIFIER: 'Identifier String',
    DATETIME: 'Date and time',
    DATE: 'Date only',
    GROUP: 'Repeating Group',
    URL: 'URL',
    MEMO: 'Memo',
    MARKDOWN: 'Mark down'
}

export const internalScopeDict: any = {
    READ: 'Read Only',
    WRITE: 'Read And Write'
}

export const validationCategory: any = {
    SCHEMA_VALIDATOR: 'Schema Validation',
    LINK_VALIDATOR: 'Link Validation',
    PRODUCT_SYNCHRONISATION: 'Sync Validation'
}

export const productTabs: any = [
    { key: "additionalInformation", label: "Product Information" },
    { key: "cardArt", label: "Card Art" },
    { key: "features", label: "Features" },
    { key: "bundles", label: "Bundles" },
    { key: "constraints", label: "Constraints" },
    { key: "depositRates", label: "Deposit Rates" },
    { key: "lendingRates", label: "Lending Rates" },
    { key: "eligibility", label: "Eligibility" },
    { key: "fees", label: "Fees & Discounts" }
]

export const lendingRateKeys: any = [
    { value: { fieldValue: "lendingRateType", rootObject: "lendingRates" }, text: "Lending Rate Type" },
    { value: { fieldValue: "rate", rootObject: "lendingRates" }, text: "Rate" },
    { value: { fieldValue: "comparisonRate", rootObject: "lendingRates" }, text: "Comparison Rate" },
    { value: { fieldValue: "calculationFrequency", rootObject: "lendingRates" }, text: "Calculation Frequency" },
    { value: { fieldValue: "applicationFrequency", rootObject: "lendingRates" }, text: "Application Frequency" },
    { value: { fieldValue: "interestPaymentDue", rootObject: "lendingRates" }, text: "Interest Payment Due" },
    { value: { fieldValue: "repaymentType", rootObject: "lendingRates" }, text: "Repayment Type" },
    { value: { fieldValue: "loanPurpose", rootObject: "lendingRates" }, text: "Loan Purpose" },
    { value: { fieldValue: "additionalValue", rootObject: "lendingRates" }, text: "Additional Value" },
    { value: { fieldValue: "additionalInfo", rootObject: "lendingRates" }, text: "Additional Info" },
    { value: { fieldValue: "additionalInfoUri", rootObject: "lendingRates" }, text: "Additional Info Uri" }
]


export const depositRateKeys: any = [
    { value: { fieldValue: "depositRateType", rootObject: "depositRates" }, text: "Deposit Rate Type" },
    { value: { fieldValue: "rate", rootObject: "depositRates" }, text: "Rate" },
    { value: { fieldValue: "calculationFrequency", rootObject: "depositRates" }, text: "Calculation Frequency" },
    { value: { fieldValue: "applicationFrequency", rootObject: "depositRates" }, text: "Application Frequency" },
    { value: { fieldValue: "additionalValue", rootObject: "depositRates" }, text: "Additional Value" },
    { value: { fieldValue: "additionalInfo", rootObject: "depositRates" }, text: "Additional Info" },
    { value: { fieldValue: "additionalInfoUri", rootObject: "depositRates" }, text: "Additional Info Uri" }
]


export const feesKeys: any = [
    { value: { fieldValue: "name", rootObject: "fees" }, text: "Name" },
    { value: { fieldValue: "feeType", rootObject: "fees" }, text: "Fee Type" },
    { value: { fieldValue: "amount", rootObject: "fees" }, text: "Amount" },
    { value: { fieldValue: "balanceRate", rootObject: "fees" }, text: "Balance Rate" },
    { value: { fieldValue: "transactionRate", rootObject: "fees" }, text: "Transaction Rate" },
    { value: { fieldValue: "accruedRate", rootObject: "fees" }, text: "Accured Rate" },
    { value: { fieldValue: "accrualFrequency", rootObject: "fees" }, text: "Accrual Frequency" },
    { value: { fieldValue: "currency", rootObject: "fees" }, text: "Currency" },
    { value: { fieldValue: "additionalValue", rootObject: "fees" }, text: "Addiotional Value" },
    { value: { fieldValue: "additionalInfo", rootObject: "fees" }, text: "Additional Info" },
    { value: { fieldValue: "additionalInfoUri", rootObject: "fees" }, text: "Additional Info Uri" },
]

export const productInfoKeys: any = [
    { value: { fieldValue: "name" }, text: "Name" },
    { value: { fieldValue: "productCategory" }, text: "Product Category" },
    { value: { fieldValue: "description" }, text: "Description" },
    { value: { fieldValue: "brand" }, text: "Brand ID" },
    { value: { fieldValue: "brandName" }, text: "Brand Display Name" },
    { value: { fieldValue: "applicationUri" }, text: "Link to Application Form" },
    { value: { fieldValue: "overviewUri", rootObject: "additionalInformation" }, text: "Link to overview information" },
    { value: { fieldValue: "termsUri", rootObject: "additionalInformation" }, text: "Link to terms and condition" },
    { value: { fieldValue: "eligibilityUri", rootObject: "additionalInformation" }, text: "Link to eligibility criteria" },
    { value: { fieldValue: "feesAndPricingUri", rootObject: "additionalInformation" }, text: "Link to fees and pricing" },
    { value: { fieldValue: "bundleUri", rootObject: "additionalInformation" }, text: "Link to bundle information" },

]

export const settingsVersionDict: any = {
    workflow: 'Workflow',
    security: 'Security',
    customTags: 'Custom Tags',
    sectionTags: 'Section Tags',
    brands: 'Brands',
    extract: 'Auto. Extracts',
    tmdValidation: 'TMD Validation',
    sync: 'Prod. Sync',
    govWorkflow: 'Gov. Workflow',
    internalProductCategories: 'Int. Prod. Categories',
    cdrProductCategories: 'CDR Prod. Categories',
    internalProductTabs: 'Int. Prod. Tabs',
    tmdSchema: 'TMD Schema',
    restored: 'Restored',
    calculation: 'Calculation'
}

export const translateConstraintType: any = (constraintType: string) => constraintTypeDict[constraintType];

export const translateConstraintAdditionalValueLabel: any = (constraintType: string) => constraintAdditionalValueLabelDict[constraintType];

export const translateDepositRateType: any = (depositRateType: string) => depositRateTypeDict[depositRateType];

export const translateDepositRateAdditionalValueLabel: any = (depositRateType: string) => depositRateAdditionalValueLabelDict[depositRateType];

export const translateLendingRateType: any = (lendingRateType: string) => lendingRateTypeDict[lendingRateType];

export const translateLendingRateAdditionalValueLabel: any = (lendingRateType: string) => lendingRateAdditionalValueLabelDict[lendingRateType];

export const translateInterestPaymentDue: any = (interestPaymentDue: string) => interestPaymentDueDict[interestPaymentDue];

export const translateRepaymentType: any = (repaymentType: string) => repaymentTypeDict[repaymentType];

export const translateLoanPurpose: any = (loanPurpose: string) => loanPurposeDict[loanPurpose];

export const translateEligibilityType: any = (eligibilityType: string) => eligibilityTypeDict[eligibilityType];

export const translateEligibilityAdditionalValueLabel: any = (eligibilityType: string) => eligibilityAdditionalValueLabelDict[eligibilityType];

export const translateFeatureType: any = (featureType: string) => featureTypeDict[featureType];

export const translateFeatureAdditionalValueLabel: any = (featureType: string) => featureAdditionalValueLabelDict[featureType];

export const translateFeeType: any = (feeType: string) => feeTypeDict[feeType];

export const translateFeeAdditionalValueLabel: any = (feeType: string) => feeAdditionalValueLabelDict[feeType];

export const translateDiscountType: any = (discountType: string) => discountTypeDict[discountType];

export const translateDiscountAdditionalValueLabel: any = (discountType: string) => discountAdditionalValueLabelDict[discountType];

export const translateDiscountEligibilityType: any = (discountEligibilityType: string) => discountEligibilityTypeDict[discountEligibilityType];

export const translateChangeTypeDict: any = (changeType: string) => changeTypeDict[changeType];

export const translateLabelDict: any = (label: string) => labelTypeDict[label];

export const translateDiscountEligibilityAdditionalValueLabel: any = (discountEligibilityType: string) => discountEligibilityAdditionalValueLabelDict[discountEligibilityType];

export const translateUnitOfMeasure: any = (unitOfMeasure: string) => unitOfMeasureDict[unitOfMeasure];

export const translateRateApplicationMethod: any = (rateApplicationMethod: string) => rateApplicationMethodDict[rateApplicationMethod];

export const translateUserActivityLogDict: any = (activityLogType: string) => userActivityLogDict[activityLogType];

export const translateLvrDict: any = (lvrType: string) => lvrDict[lvrType];

export const translateYearFixedDict: any = (year: string) => yearFixedDict[year]

export const translateValidationCategory: any = (category: string) => validationCategory[category];

export const translateProductCategory: any = (store: any, category: any) => {
    if (productCategoryDict[category]) {
        return productCategoryDict[category];
    } else {
        try {
            if (!store) {
                return category;
            }
            else {
                // Hopefully this fixes the warning being returned
                if (store.state.organisation.settings.internalProductCategories) {
                    for (const internalCategory of store.state.organisation.settings.internalProductCategories.productCategories) {
                        if (internalCategory.key === category) {
                            return internalCategory.label;
                        }
                    }
                }

            }

        } catch (e) { console.log(e) }
    }
    return category;
}






export const translateTmdCategory: any = (store: any, category: any) => {
    try {
        return store.state.organisation.settings.tmdSchema.categories[category].label;
    } catch (e) {
        return 'INVALID'
    }
}
