import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Argon from './plugins/argon-kit'
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css"
import { AuthPlugin } from './auth/auth'
import Notifications from '@kyvg/vue3-notification';
import './assets/scss/custom.scss';
import { MotionPlugin } from '@vueuse/motion'
// import VueNotifications from 'vue-notification'

// Import the Auth0 configuration
// import { envs } from '../auth_config.json'
import authConfig from '../auth_config.json';

const env = process.env.VUE_APP_ENV || 'dev';

console.log(`environment: ${env}`);

store.state.apiBase = (authConfig.envs as any)[env].apiBase;
const clientId = (authConfig.envs as any)[env].clientId;
const audience = (authConfig.envs as any)[env].audience;
const domain = (authConfig.envs as any)[env].domain;

const app = createApp(App);

app.use(MotionPlugin);

app.config.warnHandler = function (msg, vm, trace) {
};

// Install the authentication plugin
app.use(AuthPlugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState: any) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        )
    }
})

// Install Argon components
app.use(Argon)

app.use(router);
app.use(store);
app.use(Notifications)

app.mount('#app')
