import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notification_toast_vue = _resolveComponent("notification-toast-vue")!
  const _component_scroll_to_top = _resolveComponent("scroll-to-top")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, { name: "header" }),
    _createVNode(_component_notification_toast_vue, {
      group: "app-notifications",
      toasty: _ctx.toasty
    }, null, 8, ["toasty"]),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_KeepAlive, { include: ['product-portfolio-page', 'tmds-view-page', 'market-portfolio-page'] }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_router_view, { name: "footer" }),
    _createVNode(_component_scroll_to_top)
  ]))
}