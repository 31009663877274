
<template>
  <div :class="{'active': isActive}" v-show="isActive" :id="tabId">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, inject, watch, onUnmounted } from 'vue';

export default defineComponent({
  name: 'field-tab',
  props: {
    title: {
      type: String,
      required: true,
    },
    hasWarning: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean
    }
  },
  setup(props) {
    const isActive = ref(props.active !== undefined ?  props.active : false);
    const tabId = `tab-${Math.random().toString(36).substring(7)}`;

    const tabsContainer:any = inject('tabsContainer');

    onMounted(() => {
      tabsContainer.addTab({ title: props.title, isActive, tabId, hasWarning: props.hasWarning  }, props.active);
    });

    onUnmounted(() => {
        tabsContainer.removeTab(tabId);
    })

    watch(() => props.hasWarning, () => {
      if (props.hasWarning !== undefined) {
        tabsContainer.updateTab({ tabId, hasWarning: props.hasWarning });
      }
    })

    return {
      isActive,
      tabId
    };
  },
});
</script>

<style scoped>
</style>
