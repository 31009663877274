import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-256e1f12"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "autocomplete"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_SwapOutlined = _resolveComponent("SwapOutlined")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex search-container align-items-center", _ctx.className])
  }, [
    _createVNode(_component_SearchOutlined, { class: "search-icon" }),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event)),
      class: _normalizeClass([
      'form-control',
      `form-control-${_ctx.inputSize}`,
      'holder-searchbar',
      ]),
      placeholder: _ctx.placeholder,
      autocomplete: _ctx.autocomplete
    }, null, 10, _hoisted_1), [
      [_vModelText, _ctx.searchString]
    ]),
    (_ctx.allowIdSearch)
      ? _withDirectives((_openBlock(), _createBlock(_component_SwapOutlined, {
          key: 0,
          type: "button",
          class: "ms-2 pe-1",
          onClick: _ctx.swapToID
        }, null, 8, ["onClick"])), [
          [_directive_tooltip, _ctx.swapToolTip]
        ])
      : _createCommentVNode("", true),
    (_ctx.searchString)
      ? (_openBlock(), _createBlock(_component_CloseOutlined, {
          key: 1,
          type: "button",
          class: "ms-2 pe-1",
          onClick: _ctx.clearSearch
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ], 2))
}