import * as Axios from 'axios';
import BaseService from '../../../framework/services/BaseService';
import store from '@/store';
import { IProduct } from '@/modules/products/domain/types';

export default class PortfolioService extends BaseService {
    public async getProducts(orgID: string): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/products`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IProduct[];
    }

    public async getProductsByLastUpdated(orgID: string, lastUpdated: string): Promise<any> {
        const apiConfig: Axios.AxiosRequestConfig = {
            method: 'get',
            url: `${store.state.apiBase}/private/organisations/${orgID}/products`,
            headers: { Authorization: `Bearer ${store.state.$auth.token}` },
            params: { lastUpdated: lastUpdated }
        }

        const res: Axios.AxiosResponse = await this.makeAxiosCall(apiConfig)
        return res.data as IProduct[];
    }
}
