import Vue from "vue";
import {
    createRouter,
    createWebHistory,
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteLocationRaw,
    RouteRecordRaw
} from 'vue-router'
import * as R from "vue-router";
import AppHeader from "@/modules/products/components/layout/AppHeader.vue";
import AppHeaderSimple from "@/framework/components/layout/AppHeaderSimple.vue";
import AppFooter from "@/framework/components/layout/AppFooter.vue";
import store from "@/store";
import { ModuleRegister } from "@/modules/register"

// Guards ------------------------------

const guardSecure: R.NavigationGuard = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: (to?: RouteLocationRaw | false | ((vm: typeof Vue) => any) | void) => void
) => {
    store.state.showSignIn = false;
    store.state.showSecure = true;
    try {
        const { viewType } = to.params || {};
        const previousRoutes = store.state.previousRoutes;

        if (viewType) {
            let path = to.fullPath.replace(viewType as string, '').replace(/\/$/, '');
            if (path !== from.fullPath && !store.state.backButtonRoute && !from.fullPath.includes('edit')) {
                store.state.previousRoute = from;
                previousRoutes.push(from);
            }
        } else if (!store.state.backButtonRoute && !from.fullPath.includes('edit')) {
            store.state.previousRoute = from;
            previousRoutes.push(from);
        }

        if (store.state.backButtonRoute) {
            previousRoutes.pop();
            store.state.backButtonRoute = false;
        }

        const normalizePath = (path: string) => path.replace(/\/(published|wip)$/, '');

        if (previousRoutes?.length) {
            const lastRoute = previousRoutes[previousRoutes.length - 1];
            if (normalizePath(lastRoute?.fullPath || '') === normalizePath(to.fullPath)) {
                previousRoutes.pop();
            }
        }
    } catch (error) {
        console.log("Error in route handling logic:", error);
    }

    try {
        if (to.fullPath == '/landing') {
            store.state.previousRoutes = []
        }
    } catch (error) {
        console.log("is this where the error comes from?")
    }

    // If loading has already finished, check our auth state using `fn()`
    try {
        // Watch for the loading property to change before we check isAuthenticated
        if (store.state.$auth.loading) {
            await new Promise<void>((resolve) => {
                const unwatch = store.state.$auth.$watch('loading', (loading: boolean) => {
                    if (!loading) { // Process is done
                        unwatch(); // Stop watching
                        resolve();
                    }
                });
            });
        }

        if (store.state.$auth.isAuthenticated) {
            return next();
        } else {
            return next({ name: 'Login', query: { targetUrl: to.fullPath } });
        }
    } catch (error) {
        console.log("is it in auth checking?")
    }

    next();
};

const guardPublic: R.NavigationGuard = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: (to?: RouteLocationRaw | false | ((vm: typeof Vue) => any) | void) => void
) => {
    store.state.showSignIn = true;
    store.state.showSecure = false;
    next();
};

const guardLogin = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) => {
    if (to.query['token']) {
        // Perform silent login with the token
        await store.state.$auth.silentLogin(to.query['token']);
        await store.state.$auth.completeLogin(to.query['targetUrl']);
    }
    else if (store.state.$auth.isAuthenticated) {
        next("/landing");
    } else {
        next();
    }
};


// Static Routes -----------------------

const authRoutes: RouteRecordRaw[] = [
    {
        path: "/login",
        name: "Login",
        beforeEnter: guardLogin,
        components: {
            default: () => import("./framework/views/Login.vue"),
        },
    },
    {
        path: "/resetPassword/:resetId",
        name: "ResetPassword",
        beforeEnter: guardLogin,
        components: {
            default: () => import("./modules/common/views/public/ResetPassword.vue"),
        },
    },
    {
        path: "/sso",
        name: "sso",
        beforeEnter: guardSecure,
        components: {
            default: () => import("./modules/common/views/public/SSO.vue"),
        },
    },
    {
        path: "/logout",
        name: "Logout",
        components: {
            header: AppHeaderSimple,
            default: () => import("./framework/views/Logout.vue"),
            footer: AppFooter,
        },
    }
];

const commonRoutes: RouteRecordRaw[] = [
    {
        path: "/",
        beforeEnter: guardSecure,
        name: "Home",
        components: {
            header: AppHeader,
            default: () => import("./modules/products/views/secure/Landing.vue"),
            footer: AppFooter,
        },
    },
    {
        path: "/profile/:tabName",
        beforeEnter: guardSecure,
        name: "Profile",
        components: {
            header: AppHeader,
            default: () => import("./modules/common/views/secure/Profile.vue"),
            footer: AppFooter,
        },
    },
    {
        path: "/release-notes",
        beforeEnter: guardSecure,
        name: "ReleaseNotes",
        components: {
            header: AppHeader,
            default: () => import("./modules/common/views/secure/ReleaseNotes.vue"),
            footer: AppFooter,
        },
    },
    {
        path: "/roadmap",
        beforeEnter: guardSecure,
        name: "Roadmap",
        components: {
            header: AppHeader,
            default: () => import("./modules/common/views/secure/Roadmap.vue"),
            footer: AppFooter,
        },
    }
];

function createRoutes(): RouteRecordRaw[] {
    const ret: RouteRecordRaw[] = [
        ...authRoutes,
        ...commonRoutes
    ]

    const moduleRegister = new ModuleRegister("/");

    for (const module of moduleRegister.modules) {
        ret.push(...module.routes(guardSecure, guardPublic));
    }

    return ret
}


// Create Router -----------------------

export default createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkExactActiveClass: "active",
    routes: createRoutes(),
    scrollBehavior: (to: any, from: any) => {
        if (to.hash) {
            return { el: to.hash };
        } else if (to.name === "Profile" && to.name === from.name) {
            return {};
        } else {
            return { left: 0, top: 0 };
        }
    },
});